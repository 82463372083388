<template>
  <v-card
    :class="{ 'active-card': active }"
    elevation="1"
  >
    <v-list-item
      @click="$emit('cardClicked', product, selectedTable, rows, selectedPrint)"
    >
      <v-list-item-avatar
        class="elevation-3"
        color="grey lighten-4"
        size="80"
        rounded
      >
        <v-img
          v-if="product.images.length > 0"
          :src="$store.state.imagePath + product.images[0].src"
        />
        <v-icon
          v-else
          color="primary"
        >
          fas fa-image
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          class="text-caption font-weight-bold secondary--text"
        >
          {{ product.product_name }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption">
          {{ product.product_reference }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-simple-table
      v-if="rows.length > 0"
      fixed-header
      height="120"
      dense
    >
      <template v-slot:default>
        <thead>
          <tr
            v-if="
              (product.is_composition == 0 &&
                product.product_vendors.cost_tables[0].title != null) ||
              selectedTable != null
            "
          >
            <th
              colspan="3"
              class="text-center"
            >
              <v-menu
                open-on-hover
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                    class="primary--text text-decoration-underline"
                  >
                    <v-icon
                      color="primary"
                      v-show="selectedTable.printed"
                      >mdi-table</v-icon
                    >
                    {{
                      selectedTable.printed
                        ? selectedTable.title
                        : selectedPrint.name
                    }}
                  </span>
                </template>
                <v-card
                  class="overflow-auto"
                  max-height="150"
                >
                  <v-list dense>
                    <v-list-item
                      v-for="(item, idx) in product.product_vendors.cost_tables"
                      :key="idx"
                      @click="selectTable(item)"
                    >
                      <v-list-item-title class="caption">
                        <v-icon
                          color="primary"
                          v-show="item.printed"
                          >mdi-table</v-icon
                        >
                        {{ idx == 0 ? selectedPrint.name : item.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </th>
          </tr>
          <tr align="end">
            <th class="text-left">Qtd.</th>
            <th class="text-right">UN</th>
            <th class="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, idx) in rows"
            :key="idx"
          >
            <td class="text-caption secondary--text">
              {{ row.quantity }}
            </td>
            <td class="text-caption secondary--text text-right">
              {{ calculatePrice(row).unity }}
            </td>
            <td class="text-caption secondary--text text-right">
              {{ calculatePrice(row).total }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider class="mx-4" />

    <v-card-text class="pa-0">
      <v-slide-group
        v-model="selectedColor"
        center-active
        mandatory
        show-arrows
        prev-icon="fas fa-caret-left"
        next-icon="fas fa-caret-right"
      >
        <v-slide-item
          v-for="(color, idx) in colors"
          :key="idx"
          v-slot="{ active, toggle }"
          :value="color"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                class="ma-1 overflow-hidden d-flex align-center justify-center"
                @click="toggle"
                height="20"
                width="50"
                v-on="on"
                v-bind="attrs"
              >
                <v-img
                  v-if="color.image_color"
                  align="center"
                  :src="$store.state.imagePath + color.image_color"
                  width="100%"
                  height="100%"
                >
                  <v-icon
                    x-small
                    dark
                    v-if="active"
                    >fas fa-check</v-icon
                  >
                </v-img>
                <v-icon
                  size="15"
                  v-if="!color.image_color && !active"
                  >mdi-palette</v-icon
                >
                <v-icon
                  x-small
                  v-if="active"
                  >fas fa-check</v-icon
                >
              </v-card>
            </template>
            <span>{{ color.name }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </v-card-text>

    <v-card-actions>
      <v-chip
        class="caption font-weight-bold"
        :color="switchColor(product.product_vendors)"
        x-small
        dark
        v-if="product.product_vendors"
      >
        {{ checkVendorStatus(product.product_vendors) }}
      </v-chip>

      <v-chip
        class="caption font-weight-bold"
        color="primary"
        x-small
        dark
        v-else
      >
        <span>Composto</span>
      </v-chip>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-on="on"
            v-bind="attrs"
            class="caption font-weight-regular secondary--text"
          >
            {{ product.update_time }}
          </span>
        </template>
        <span>Data de atualização</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { calculate } from '@/main.js'
export default {
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    product: {
      type: Object,
      required: true,
    },
    settings: Object,
    selectedPrint: Object,
    fixedValue: Number,
  },
  data: () => ({
    model: null,
    colors: [],
    selectedColor: null,
    selectedTable: null,
  }),
  watch: {
    selectedTable: {
      handler(newvalue) {
        if (!newvalue) {
          this.selectedTable = this.product.product_vendors.cost_tables[0]
        }
      },
      immediate: true,
    },
  },
  computed: {
    print() {
      return this.selectedTable.printed
        ? this.selectedTable
        : this.selectedPrint
    },
    active() {
      let isActive = this.$store.state.cartProductsIds.find(
        (el) => this.product.product_id == el
      )
      if (isActive != null) {
        return true
      }
      return false
    },
  },
  methods: {
    checkVendorStatus(vendor) {
      let status = vendor.vendor_status

      switch (status) {
        case 1:
          return 'OK'
        case 2:
          return 'Consultar'
        case 3:
          return 'Sem estoque'
        case 4:
          return 'Fora de linha'
      }
    },
    switchColor(vendor) {
      let status = vendor.vendor_status

      switch (status) {
        case 1:
          return 'success'
        case 2:
          return 'blue'
        case 3:
          return 'error'
        case 4:
          return 'gray'
      }
    },
    calculatePrice(row) {
      let total = calculate.price(
        row,
        this.print,
        this.product.product_vendors.cost_tables[0],
        this.$store.state.fixedValue,
        this.product.factor ? this.product.factor.rows : []
      )

      total = total ? total : 0
      let unity = total / row.quantity
      unity = unity ? unity : 0

      total = total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
      unity = unity.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })

      return {
        total: total,
        unity: unity,
      }
    },

    convertMoney(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
    selectTable(table) {
      this.selectedTable = table
    },
    getColors() {
      var colorArr = []
      this.product.images.map((el) => {
        var control = 0
        colorArr.map((color) => {
          if (color.id == el.color_id) {
            control++
          }
        })
        if (control == 0) {
          colorArr.push(el.color)
        }
      })
      this.colors = colorArr
    },
  },
}
</script>

<style>
.active-card {
  border: 1px solid var(--v-primary-base) !important;
}
</style>